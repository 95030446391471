<template>
    <div class="badges-frame white">
        <div class="badges-frame-inner">
            <div v-if="level" class="badges-frame-level">
                <h3>Your Coco Level: <span>{{ level.platform }} {{ level.achievedLevel }}</span></h3>
                <LevelChart :level="level" />
            </div>
            <div v-for="(set, i) in badgeSets" :key="i" class="badges-frame-set">
                <h3>{{ set[0].type !== 'Attendance' ? set[0].type + 's' : set[0].type }}</h3>
                <BadgeSet :badges="set" />
            </div>
        </div>
    </div>
</template>

<script setup>
import BadgeSet from "@/components/badges/BadgeSet.vue";
import LevelChart from "@/components/levels/LevelChart.vue";
import { computed } from "vue";
import _ from 'lodash';

const props = defineProps({
    badges: Object,
    level: Object
});

const badgeSets = computed(() => {
    return _.groupBy(props.badges, (b) => { return b.type });
});

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.badges-frame {
    padding: 2vh 0;

    &-inner {
        padding: 4vh 6vh;

        @media screen and (max-width: $minTablet) {
            padding: 4vh;
        }
    }

    &-set {
        display: flex;
        flex-wrap: wrap;
        padding-top: 3rem;
        margin-bottom: 3rem;
        position: relative;

        h3 {
            position: absolute;
            top: 0;
            left: 0;
            color: $indigo;
        }

        :deep {
            .badge {
                width: 7rem;
            }
        }
    }

    &-level {
        margin-bottom: 4vh;
        align-items: center;

        :deep {
            .level-banner {
                width: 8rem;
            }
        }

        h3 {
            margin-bottom: 2vh;
            display: inline-block;
            margin-right: 2vh;

            span {
                font-weight: bold;

                @media screen and (max-width: $minTablet) {
                    display: block
                }
            }
        }
    }
}
</style>
