<template>
  <div ref="badgeEl"
    :class="['badge', badge.achieved ? 'on' : '', badge.type ? `badge-type-${badge.type.toLowerCase()}` : '']">
    <img :src="badge.imageUrl" class="badge-image" />
    <div v-if="badge.count > 1" class="badge-count">{{ badge.count }}</div>
    <img v-if="overlayImage" class="badge-overlay" :src="overlayImage" />
  </div>
  <ToolTip :message="description" :anchor="badgeEl" />
</template>

<script setup>
import ToolTip from "@/components/ToolTip.vue";
import { ref, computed } from "vue";

const badgeEl = ref(null);

const props = defineProps({
  badge: Object
});

const overlayImage = computed(() => {
  var result;

  if (props.badge.type === 'Theme') {
    result = `/assets/badge-overlays/badge-overlay-${getPlatform().toLowerCase()}.png`
  }

  return result;
});

const description = computed(() => {
  if (props.badge.type === 'Theme') {
    return `${props.badge.description} in ${getPlatform()}`;
  }
  else return props.badge.description;
});

function getPlatform() {
  return props.badge.contentType == 'Text' ? 'Javascript' : 'Scratch';
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.badge {
  width: 8rem;
  opacity: 0.3;
  z-index: 100;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;

  &-overlay {
    position: absolute;
    left: 5%;
    width: 20%;
    top: 45%;
  }

  &:hover {
    transform: scale(1.5);
    opacity: 1;
    z-index: 500;
  }

  &.on {
    opacity: 1;
  }

  &-image {
    width: 100%;
  }

  &-count {
    position: absolute;
    right: 10%;
    top: 10%;
    box-shadow: $shadow;
    background: $red;
    color: white;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-type-concept {
    .badge-count {
      right: 20%;
      top: 20%;
    }
  }
}
</style>
