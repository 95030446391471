<template>
  <div class="badge-set">
    <Badge v-for="(badge, i) in badges" :key="i" :badge="badge" />
  </div>
</template>

<script setup>
import Badge from "@/components/badges/Badge.vue";
import { computed } from "vue";

const props = defineProps({
  badges: Object
});

const title = computed(() => {
  return props.badges[0].type;
})

</script>

<style lang="scss" scoped></style>
